<template>
  <div>
    <b-card class="mb-0 border-0 p-0">
      <b-row>
        <b-col cols="4">
          <b-form-group label="Upload File">
            <b-form-file
              v-model="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Merchant">
            <b-form-select
              v-model="merchant"
              :options="merchants"
              value-field="id"
              text-field="description"
              placeholder="Select a merchant"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <template #footer>
        <div class="d-flex justify-content-between">
          <b-button variant="primary" @click="processFile()">
            <feather-icon icon="SaveIcon" />
            <span class="ml-1"> Process </span>
          </b-button>
          <b-button
            variant="success"
            v-if="isImported && imports.length > 0"
            @click="processImport()"
          >
            <feather-icon icon="CheckIcon" />
            <span class="ml-1"> Submit </span>
          </b-button>
        </div>
      </template>
    </b-card>
    <b-form-group v-if="isImported" label-class="px-2">
      <template #label>
        <h4>Number of items to Import: {{ totalImportedPayments }}</h4>
      </template>
      <b-table
        class="position-relative font-small-3"
        primary-key="id"
        empty-text="No matching records found"
        select-mode="multi"
        responsive="sm"
        table-class="text-nowrap"
        small
        sort-icon-left
        ref="refRegisterPaymentOtherTable"
        :items="imports"
        :fields="fields"
        :busy.sync="isBusy"
        show-empty
        sticky-header="68vh"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(status)="data">
          <span
            :class="
              data.item.status == 'Settled Successfully' ||
              data.item.status == 'Captured/Pending Settlement'
                ? 'text-success'
                : 'text-danger'
            "
          >
            {{ data.item.status }}
          </span>
        </template>
      </b-table>
    </b-form-group>
  </div>
</template>

<script>
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service.js";
import { mapGetters } from "vuex";
export default {
  mounted() {
    this.getAllMerchants();
  },
  data() {
    return {
      file: null,
      merchant: null,
      merchants: [],
      imports: [],
      isBusy: false,
      isImported: false,
      fields: [
        {
          key: "transaction_id",
          label: "Transaction",
        },
        {
          key: "merchant",
          label: "Merchant",
        },
        {
          key: "name_file",
          label: "Client Authorize",
        },
        {
          key: "name_bd",
          label: "Client A.M.G",
        },
        {
          key: "account",
          label: "Account",
        },
        {
          key: "program",
          label: "Program",
        },
        {
          key: "name_advisor",
          label: "Advisor",
        },
        {
          key: "amount",
          label: "Amount",
          formatter: (value) => {
            return `$ ${value}`;
          },
        },
        {
          key: "card_number",
          label: "Credit Card",
        },
        {
          key: "status",
          label: "Transaction Status",
        },
        {
          key: "date",
          label: "Settlement Date/Time",
          formatter: (value) => {
            return this.$options.filters.myGlobalDay(value);
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    totalImportedPayments() {
      return this.imports.length;
    },
  },
  methods: {
    async getAllMerchants() {
      try {
        const { data: merchants } =
          await RegisterPaymentService.getAllMerchants();
        this.merchants = merchants;
      } catch (error) {
        console.error(error, "error");
      }
    },
    async processImport() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const response = await RegisterPaymentService.processImport({
          id: this.currentUser.user_id,
          merchant: this.merchant,
        });
        let html =
          "Transactions Successfull : " +
          response.data.successfull_transactions +
          "<br>" +
          "Transactions Declined : " +
          response.data.wrong_transactions;
        this.showSuccessSwal("Import Finished Successfully", null, html);
        this.$router.push({ name: "adm-register-payments-automatic" })
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async processFile() {
      try {
        this.addPreloader();
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("merchant", this.merchant);
        formData.append("user_id", this.currentUser.user_id);
        await RegisterPaymentService.importAutomaticPayments(formData);
        this.getExcelDetail();
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
    async getExcelDetail() {
      try {
        const { data: response } = await RegisterPaymentService.getExcelDetail({
          id: this.currentUser.user_id,
        });
        this.imports = response;
        this.isImported = true;
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>